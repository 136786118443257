import { createSlice } from "@reduxjs/toolkit";
const initialState = {
	seconds: 0,
	minutes: 1,
};
const timerSlice = createSlice({
	name: "timer",
	initialState,
	reducers: {
		setSeconds: (state, action) => {
			state.seconds = action.payload;
		},
		setMinutes: (state, action) => {
			state.minutes = action.payload;
		},
		resetTimer: () => {
			return initialState;
		},
	},
});
export const { setSeconds, setMinutes, resetTimer } = timerSlice.actions;
export default timerSlice.reducer;
