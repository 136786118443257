import { createSlice } from "@reduxjs/toolkit";
import { SortingState } from "@tanstack/react-table";

interface LeadsTableStateInterface {
	tab:
		| "inbox"
		| "in_progress"
		| "form_submitted"
		| "appointments"
		| "archived";
	search: string;
	inboxPageNumber: number;
	inProgressPageNumber: number;
	formSubmittedPageNumber: number;
	appointmentPageNumber: number;
	archivedPageNumber: number;
	inboxRowsPerPage: number;
	inProgressRowsPerPage: number;
	formSubmittedRowsPerPage: number;
	appointmentRowsPerPage: number;
	archivedRowsPerPage: number;
	leadsInboxSorting: SortingState;
	leadsInProgressSorting: SortingState;
	leadsFormSubmittedSorting: SortingState;
	leadsAppointmentSorting: SortingState;
	leadsArchivedSorting: SortingState;
}
const initialState: LeadsTableStateInterface = {
	tab: "inbox",
	search: "",
	inboxPageNumber: 1,
	inProgressPageNumber: 1,
	formSubmittedPageNumber: 1,
	appointmentPageNumber: 1,
	archivedPageNumber: 1,
	inboxRowsPerPage: 20,
	inProgressRowsPerPage: 20,
	formSubmittedRowsPerPage: 20,
	appointmentRowsPerPage: 20,
	archivedRowsPerPage: 20,
	leadsInboxSorting: [],
	leadsInProgressSorting: [],
	leadsFormSubmittedSorting: [],
	leadsAppointmentSorting: [],
	leadsArchivedSorting: [],
};

export const leadsTableStateSlice = createSlice({
	name: "leadsTableState",
	initialState,
	reducers: {
		setLeadsTab: (state, action) => {
			state.tab = action.payload;
		},
		setLeadsSearch: (state, action) => {
			state.search = action.payload;
		},
		setLeadsInboxPageNumber: (state, action) => {
			state.inboxPageNumber = action.payload;
		},
		setLeadsInProgressPageNumber: (state, action) => {
			state.inProgressPageNumber = action.payload;
		},
		setLeadsFormSubmittedPageNumber: (state, action) => {
			state.formSubmittedPageNumber = action.payload;
		},
		setLeadsAppointmentPageNumber: (state, action) => {
			state.appointmentPageNumber = action.payload;
		},
		setLeadsArchivedPageNumber: (state, action) => {
			state.archivedPageNumber = action.payload;
		},
		setLeadsInboxRowsPerPage: (state, action) => {
			state.inboxRowsPerPage = action.payload;
		},
		setLeadsInProgressRowsPerPage: (state, action) => {
			state.inProgressRowsPerPage = action.payload;
		},
		setLeadsFormSubmittedRowsPerPage: (state, action) => {
			state.formSubmittedRowsPerPage = action.payload;
		},
		setLeadsAppointmentRowsPerPage: (state, action) => {
			state.appointmentRowsPerPage = action.payload;
		},
		setLeadsArchivedRowsPerPage: (state, action) => {
			state.archivedRowsPerPage = action.payload;
		},
		setLeadsInboxSorting: (state, action) => {
			state.leadsInboxSorting = action.payload;
		},
		setLeadsInProgressSorting: (state, action) => {
			state.leadsInProgressSorting = action.payload;
		},
		setLeadsFormSubmittedSorting: (state, action) => {
			state.leadsFormSubmittedSorting = action.payload;
		},
		setLeadsAppointmentSorting: (state, action) => {
			state.leadsAppointmentSorting = action.payload;
		},
		setLeadsArchivedSorting: (state, action) => {
			state.leadsArchivedSorting = action.payload;
		},
		resetLeadsTableState: () => {
			return initialState;
		},
	},
});

export const {
	setLeadsTab,
	setLeadsSearch,
	setLeadsInboxPageNumber,
	setLeadsInProgressPageNumber,
	setLeadsFormSubmittedPageNumber,
	setLeadsAppointmentPageNumber,
	setLeadsArchivedPageNumber,
	setLeadsInboxRowsPerPage,
	setLeadsInProgressRowsPerPage,
	setLeadsFormSubmittedRowsPerPage,
	setLeadsAppointmentRowsPerPage,
	setLeadsArchivedRowsPerPage,
	resetLeadsTableState,
	setLeadsInboxSorting,
	setLeadsInProgressSorting,
	setLeadsFormSubmittedSorting,
	setLeadsAppointmentSorting,
	setLeadsArchivedSorting,
} = leadsTableStateSlice.actions;

export default leadsTableStateSlice.reducer;
