import { Dispatch } from "@reduxjs/toolkit";
import { unSetPrimaryPatientData } from "../redux/addPartner";
import { patientsUnSetPrimaryPatientData } from "../redux/addPartnerPatients";
import { resetLanguageLoader } from "../redux/language";
import { resetLeadsFilters } from "../redux/leadsFilters";
import { resetLeadsTableState } from "../redux/LeadsTableState";
import { resetMenus } from "../redux/menus";
import { resetPatientsFilters } from "../redux/patientsFilter";
import { resetPatientsTableState } from "../redux/patientsTableState";
import { resetPermissions } from "../redux/permissions";
import { resetPublicWaitlistTableState } from "../redux/publicWaitlistTableState";
import { resetReferralsTableState } from "../redux/referralsTableState";
import { resetReferringPhysiciansTableState } from "../redux/referringPhysiciansTableState";
import { resetUser } from "../redux/user";
import { resetUserManagementFilters } from "../redux/userManagementFilters";
import { resetUserManagementTableState } from "../redux/userManagementTableStates";
import { resetAuth } from "../redux/zendeskAuth";

// Helper function to reset all states
export const clearAllReduxStates = (dispatch: Dispatch) => {
	dispatch(unSetPrimaryPatientData());
	dispatch(patientsUnSetPrimaryPatientData());
	dispatch(resetLanguageLoader());
	dispatch(resetLeadsFilters());
	dispatch(resetLeadsTableState());
	dispatch(resetMenus());
	dispatch(resetPatientsFilters());
	dispatch(resetPatientsTableState());
	dispatch(resetPermissions());
	dispatch(resetPublicWaitlistTableState());
	dispatch(resetReferralsTableState());
	dispatch(resetReferringPhysiciansTableState());
	dispatch(resetUser());
	dispatch(resetUserManagementFilters());
	dispatch(resetUserManagementTableState());
	dispatch(resetAuth());
};
