import { createSlice } from "@reduxjs/toolkit";
import { SortingState } from "@tanstack/react-table";

interface PublicWaitlistTableStateInterface {
	tab: "active" | "archived";
	search: string;
	activePageNumber: number;
	archivedPageNumber: number;
	activeRowsPerPage: number;
	archivedRowsPerPage: number;
	waitlistActiveSorting: SortingState;
	waitlistArchivedSorting: SortingState;
}
const initialState: PublicWaitlistTableStateInterface = {
	tab: "active",
	search: "",
	activePageNumber: 1,
	archivedPageNumber: 1,
	activeRowsPerPage: 20,
	archivedRowsPerPage: 20,
	waitlistActiveSorting: [],
	waitlistArchivedSorting: [],
};

export const publicWaitlistTableStateSlice = createSlice({
	name: "publicWaitlistTableState",
	initialState,
	reducers: {
		setPublicWaitlistTab: (state, action) => {
			state.tab = action.payload;
		},
		setPublicWaitlistSearch: (state, action) => {
			state.search = action.payload;
		},
		setPublicWaitlistActivePageNumber: (state, action) => {
			state.activePageNumber = action.payload;
		},
		setPublicWaitlistArchivedPageNumber: (state, action) => {
			state.archivedPageNumber = action.payload;
		},
		setPublicWaitlistActiveRowsPerPage: (state, action) => {
			state.activeRowsPerPage = action.payload;
		},
		setPublicWaitlistArchivedRowsPerPage: (state, action) => {
			state.archivedRowsPerPage = action.payload;
		},
		setPublicWaitlistActiveSorting: (state, action) => {
			state.waitlistActiveSorting = action.payload;
		},
		setPublicWaitlistArchivedSorting: (state, action) => {
			state.waitlistArchivedSorting = action.payload;
		},
		resetPublicWaitlistTableState: () => {
			return initialState;
		},
	},
});

export const {
	setPublicWaitlistTab,
	setPublicWaitlistSearch,
	setPublicWaitlistActivePageNumber,
	setPublicWaitlistArchivedPageNumber,
	setPublicWaitlistActiveRowsPerPage,
	setPublicWaitlistArchivedRowsPerPage,
	resetPublicWaitlistTableState,
	setPublicWaitlistActiveSorting,
	setPublicWaitlistArchivedSorting,
} = publicWaitlistTableStateSlice.actions;

export default publicWaitlistTableStateSlice.reducer;
