import { createSlice } from "@reduxjs/toolkit";
interface userInterface {
	id: string;
	first_name: string;
	last_name: string;
	email: string;
	lang: string;
	profile_image_link: string | null;
	isProfileImageUpdated: boolean;
	role:
		| "CNP_Administrator"
		| "CNP_CareCoordinator"
		| "CNP_MOA"
		| "CNP_PatientsNavigator"
		| "CNP_Nurse"
		| "CNP_Doctor"
		| "";
}
const initialState: userInterface = {
	id: "",
	first_name: "",
	last_name: "",
	email: "",
	lang: "",
	profile_image_link: "",
	isProfileImageUpdated: false,
	role: "",
};
export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		id: (state, action) => {
			state.id = action.payload;
		},
		first_name: (state, action) => {
			state.first_name = action.payload;
		},
		last_name: (state, action) => {
			state.last_name = action.payload;
		},
		email: (state, action) => {
			state.email = action.payload;
		},
		lang: (state, action) => {
			state.lang = action.payload;
		},
		profile_image_link: (state, action) => {
			state.profile_image_link = action.payload;
		},
		setIsProfileImageUpdated: (state, action) => {
			state.isProfileImageUpdated = action.payload;
		},
		role: (state, action) => {
			state.role = action.payload;
		},
		resetUser: () => {
			return initialState;
		},
	},
});

export const {
	id,
	first_name,
	last_name,
	email,
	lang,
	profile_image_link,
	setIsProfileImageUpdated,
	role,
	resetUser,
} = userSlice.actions;

export default userSlice.reducer;
