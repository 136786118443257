// dialogSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	notificationDialogBoxState: false,
};

const miscSlice = createSlice({
	name: "misc",
	initialState,
	reducers: {
		setNotificationDialogBoxState: (state, { payload }) => {
			state.notificationDialogBoxState = payload;
		},
	},
});

export const { setNotificationDialogBoxState } = miscSlice.actions;

export default miscSlice.reducer;
