export interface LinksType {
	url: string | null;
	label: string;
	active: boolean;
}

// Created a class for each type so that we can get its keys as an array and convert it to string. We do this so that we don't have to type every key manually. Refer db.model.ts for implementation
// If a new property needs to be added to the interface. It also needs to be added to the class and it needs to be optional and readonly.

export class ArchivedReasonsClass {
	constructor(
		readonly id?: number,
		readonly type?: string,
		readonly text_type?: string | null,
		readonly sort_order?: number,
		readonly name?: string,
		readonly language_code?: string,
	) {}
}
export interface ArchivedReasonsInterface {
	id: number;
	type: string;
	text_type: string | null;
	sort_order: number;
	name: string;
	language_code: string;
}

export class DoctorListClass {
	constructor(
		readonly id?: number,
		readonly name?: string,
		readonly email?: string,
		readonly type?: "gp" | "rei",
		readonly dr_team_email?: string | null,
		readonly status?: boolean,
		readonly created_at?: string,
		readonly updated_at?: string,
		readonly deleted_at?: string | null,
	) {}
}
export interface DoctorListInterface {
	id: number;
	name: string;
	email: string;
	type: "gp" | "rei";
	dr_team_email: string | null;
	status: boolean;
	created_at: string;
	updated_at: string;
	deleted_at: string | null;
}

export class GlobalConfigClass {
	constructor(
		readonly id?: number,
		readonly key?: string,
		readonly value?: string,
		readonly is_restricted?: number,
		readonly created_at?: string | null,
		readonly updated_at?: string | null,
	) {}
}
export interface GlobalConfigInterface {
	id: number;
	key: string;
	value: string;
	is_restricted: number;
	created_at: string | null;
	updated_at: string | null;
}

export class CareCoordinatorClass {
	constructor(
		readonly id?: string,
		readonly first_name?: string,
		readonly last_name?: string,
		readonly email?: string,
		readonly phone_number?: string | null,
		readonly email_verified?: string,
		readonly phone_number_verified?: number | null,
		readonly user_status?: string,
		readonly preferred_lang?: string,
		readonly role?: string,
		readonly enabled?: boolean,
		readonly sort_order?: number,
	) {}
}
export interface CareCoordinatorInterface {
	id: string;
	first_name: string;
	last_name: string;
	email: string;
	phone_number: string | null;
	email_verified: string;
	phone_number_verified: number | null;
	user_status: string;
	preferred_lang: string;
	role: string;
	enabled: boolean;
	sort_order: number;
}

export class PriorityListClass {
	constructor(
		readonly id?: number,
		readonly name?: string,
		readonly status?: boolean,
		readonly priority_level?: number,
		readonly type?: string,
	) {}
}
export interface PriorityListInterface {
	id: number;
	name: string;
	status: boolean;
	priority_level: number;
	type: string | null;
	description: string;
}

export class ClinicLanguagesClass {
	constructor(
		readonly id?: number,
		readonly code?: string,
		readonly name?: string,
		readonly created_at?: string | null,
		readonly updated_at?: string | null,
	) {}
}
export interface ClinicLanguagesInterface {
	id: number;
	code: string;
	name: string;
	created_at: string | null;
	updated_at: string | null;
}

export class ContactResolutionClass {
	constructor(
		readonly id?: number,
		readonly name?: string,
		readonly status?: boolean,
		readonly is_default?: number,
	) {}
}
export interface ContactResolutionInterface {
	id: number;
	name: string;
	status: boolean;
	is_default: number;
}

export class VirtualCareProviderClass {
	constructor(
		readonly id?: number,
		readonly name?: string,
		readonly provider_type?: string,
		readonly status?: boolean,
		readonly created_at?: string | null,
		readonly updated_at?: string | null,
	) {}
}
export interface VirtualCareProviderInterface {
	id: number;
	name: string;
	provider_type: string;
	status: boolean;
	created_at: string | null;
	updated_at: string | null;
}

export class AppointmentCancelledReasonsClass {
	constructor(
		readonly id?: number,
		readonly reason?: string,
		readonly status?: number,
	) {}
}
export interface AppointmentCancelledReasonsInterface {
	id: number;
	reason: string;
	status: number;
}

export class RolesClass {
	constructor(readonly name?: string) {}
}
export interface RolesInterface {
	name: string;
}

export class ReferringPhysicianOptoutDropdownClass {
	constructor(readonly id?: number, readonly name?: string) {}
}
export interface ReferringPhysicianOptoutDropdownInterface {
	id: number;
	name: string;
}

export class SpecialtyDropDownClass {
	constructor(readonly id?: number, readonly name?: string) {}
}
export interface SpecialtyDropDownInterface {
	id: number;
	name: string;
}

export class LocationsClass {
	constructor(
		readonly id?: number,
		readonly name?: string,
		readonly address?: string,
		readonly latitude?: string,
		readonly longitude?: string,
		readonly status?: number,
		readonly created_at?: string | null,
		readonly updated_at?: string | null,
	) {}
}
export interface LocationsInterface {
	id: number;
	name: string;
	address: string;
	latitude: string;
	longitude: string;
	status: number;
	created_at: string | null;
	updated_at: string | null;
}

export class InitialContactChannelClass {
	constructor(
		readonly id?: number,
		readonly name?: string,
		readonly status?: boolean,
		readonly is_default?: number,
		readonly created_by?: number,
	) {}
}
export interface InitialContactChannelInterface {
	id: number;
	name: string;
	status: boolean;
	is_default: number;
	created_by: number;
}

export class BenefitProviderClass {
	constructor(
		readonly id?: number,
		readonly name?: string,
		readonly status?: boolean,
		readonly is_default?: number,
		readonly created_by?: number,
	) {}
}
export interface BenefitProviderInterface {
	id: number;
	name: string;
	status: boolean;
	is_default: number;
	created_by: number;
}

export class PatientPreferencesClass {
	constructor(
		readonly id?: number,
		readonly type?: string,
		readonly question?: string,
		readonly language_code?: string,
		readonly status?: boolean,
	) {}
}
export interface PatientPreferencesInterface {
	id: number;
	type: string;
	question: string;
	language_code: string;
	status: boolean;
}

export class ReasonForReferralsClass {
	constructor(
		readonly id?: number,
		readonly name?: string,
		readonly language_code?: string,
		readonly sort_order?: number,
		readonly status?: boolean,
	) {}
}
export interface ReasonForReferralsInterface {
	id: number;
	name: string;
	language_code: string;
	sort_order: number;
	status: boolean;
}

export class PatientLanguagesClass {
	constructor(
		readonly id?: number,
		readonly code?: string,
		readonly name?: string,
		readonly status?: boolean,
		readonly created_at?: string | null,
		readonly updated_at?: string | null,
	) {}
}
export interface PatientLanguagesInterface {
	id: number;
	code: string;
	name: string;
	status: boolean;
	created_at: string | null;
	updated_at: string | null;
}

export class ReferringPhysiciansClass {
	constructor(
		readonly id?: number,
		readonly first_name?: string,
		readonly last_name?: string,
		readonly city_name?: string,
		readonly address_1?: string,
		readonly postal_code?: string,
		readonly province?: string,
		readonly country?: string,
		readonly country_id?: number,
		readonly latitude?: number,
		readonly longitude?: number,
		readonly province_id?: number,
		readonly city_id?: number,
	) {}
}
export interface ReferringPhysiciansInterface {
	id: number;
	first_name: string;
	last_name: string;
	city_name: string;
	address_1: string;
	postal_code: string;
	province: string;
	country: string;
	country_id: number;
	latitude: number;
	longitude: number;
	province_id: number;
	city_id: number;
	place_id: string;
	email: string;
	phone: string;
}

export class CountriesClass {
	constructor(
		readonly id?: number,
		readonly name?: string,
		readonly status?: number,
		readonly created_at?: string | null,
		readonly updated_at?: string | null,
		readonly deleted_at?: string | null,
	) {}
}
export interface CountriesInterface {
	id: number;
	name: string;
	status: number;
	created_at: string | null;
	updated_at: string | null;
	deleted_at: string | null;
}

export class PronounsClass {
	constructor(
		readonly id?: number,
		readonly status?: boolean,
		readonly created_at?: string | null,
		readonly updated_at?: string | null,
		readonly name?: string,
	) {}
}
export interface PronounsInterface {
	id: number;
	status: boolean;
	created_at: string | null;
	updated_at: string | null;
	name: string;
}

export class AppointmentTypesClass {
	constructor(
		readonly id?: number,
		readonly name?: string,
		readonly visit_type?: string,
		readonly email_type?: string,
		readonly reminder_type?: string,
		readonly start_time?: string,
		readonly end_time?: string,
		readonly duration?: number,
		readonly created_by?: string,
		readonly locations?: Array<LocationsInterface>,
	) {}
}
export interface AppointmentTypesInterface {
	id: number;
	name: string;
	visit_type: string;
	email_type: string;
	reminder_type: string;
	start_time: string;
	end_time: string;
	duration: number;
	created_by: string;
	locations: Array<LocationsInterface>;
}

export class FaxReferralArchiveReasonsClass {
	constructor(
		readonly id?: number,
		readonly type?: string,
		readonly text_type?: string,
		readonly sort_order?: number,
		readonly name?: string,
		readonly language_code?: string,
	) {}
}

export interface FaxReferralArchiveReasonsInterface {
	id: number;
	type: string;
	text_type: string | null;
	sort_order: number;
	name: string;
	language_code: string;
}

export class VisitingPurposeClass {
	constructor(readonly id?: number, readonly name?: string) {}
}

export interface VisitingPurposeInterface {
	id: number;
	name: string;
}

export class CountryProvincesClass {
	constructor(
		readonly id?: number,
		readonly name?: string,
		readonly status?: number,
		readonly updated_at?: string,
		readonly conf_country_id?: number,
		readonly created_at?: string,
		readonly deleted_at?: string | null,
	) {}
}

export interface CountryProvincesInterface {
	id: number;
	name: string;
	status: number;
	updated_at: string;
	conf_country_id: number;
	created_at: string;
	deleted_at: string | null;
}

export class PreferredMethodOfContactClass {
	constructor(
		readonly id?: number,
		readonly name?: string,
		readonly language_code?: string,
		readonly status?: boolean,
		readonly sort_order?: number,
	) {}
}

export interface PreferredMethodOfContactInterface {
	id: number;
	name: string;
	language_code: string;
	status: boolean;
	sort_order: number;
}

export interface GlobalConstantValuesInterface {
	archived_reasons: Array<ArchivedReasonsInterface>;
	doctor_list: Array<DoctorListInterface>;
	global_config: Array<GlobalConfigInterface>;
	country_provinces: Array<CountryProvincesInterface>;
	care_coordinator: Array<CareCoordinatorInterface>;
	priority_list: Array<PriorityListInterface>;
	clinic_languages: Array<ClinicLanguagesInterface>;
	contact_resolution: Array<ContactResolutionInterface>;
	virtual_care_provider: Array<VirtualCareProviderInterface>;
	appointment_cancelled_reasons: Array<AppointmentCancelledReasonsInterface>;
	roles: Array<RolesInterface>;
	referring_physician_optout_dropdown: Array<ReferringPhysicianOptoutDropdownInterface>;
	speciality_dropdown: Array<SpecialtyDropDownInterface>;
	locations: Array<LocationsInterface>;
	initial_contact_channel: Array<InitialContactChannelInterface>;
	benefit_provider: Array<BenefitProviderInterface>;
	patient_prefrences: Array<PatientPreferencesInterface>;
	reason_for_refferals: Array<ReasonForReferralsInterface>;
	patient_languages: Array<PatientLanguagesInterface>;
	referring_physicians: Array<ReferringPhysiciansInterface>;
	country: Array<CountriesInterface>;
	pronouns: Array<PronounsInterface>;
	appointment_types: Array<AppointmentTypesInterface>;
	fax_archived_reasons: Array<FaxReferralArchiveReasonsInterface>;
	visiting_purpose: Array<VisitingPurposeInterface>;
	preferred_method_of_contact: Array<PreferredMethodOfContactInterface>;
}
