import { createSlice } from "@reduxjs/toolkit";

interface snackbarProps {
	open: boolean;
	message: string;
	type: "success" | "error" | "warning" | "info";
}

const initialState: snackbarProps = {
	open: false,
	message: "",
	type: "success",
};

export const snackBarSlice = createSlice({
	name: "snackbar",
	initialState,
	reducers: {
		setSnackBarOptions: (
			state,
			action: {
				payload: {
					open: boolean;
					message: string;
					type: "success" | "error" | "warning" | "info";
				};
				type: any;
			},
		) => {
			state.open = action.payload.open;
			state.message = action.payload.message;
			state.type = action.payload.type;
		},
	},
});

export const { setSnackBarOptions } = snackBarSlice.actions;

export default snackBarSlice.reducer;
