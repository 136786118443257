import { createSlice } from "@reduxjs/toolkit";

interface UserManagementFiltersInterface {
	status: string;
	role: string;
}
const initialState: UserManagementFiltersInterface = {
	status: "",
	role: "",
};

export const userManagementFiltersSlice = createSlice({
	name: "userManagementFilters",
	initialState,
	reducers: {
		setTeamsStatus: (state, action) => {
			state.status = action.payload;
		},
		setTeamsRole: (state, action) => {
			state.role = action.payload;
		},
		resetUserManagementFilters: () => {
			return initialState;
		},
	},
});

export const { setTeamsStatus, setTeamsRole, resetUserManagementFilters } =
	userManagementFiltersSlice.actions;

export default userManagementFiltersSlice.reducer;
