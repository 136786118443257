import { createSlice } from "@reduxjs/toolkit";

const initialState: {
	careCoordinator: string;
	preDiagnosis: number;
} = {
	careCoordinator: "-1",
	preDiagnosis: -1,
};

export const leadsFiltersSlice = createSlice({
	name: "leadsFilters",
	initialState,
	reducers: {
		setCareCoordinator: (state, action) => {
			state.careCoordinator = action.payload;
		},
		unsetCareCoordinator: (state) => {
			state.careCoordinator = "-1";
		},
		setPreDiagnosis: (state, action) => {
			state.preDiagnosis = action.payload;
		},
		unsetPreDiagnosis: (state) => {
			state.preDiagnosis = -1;
		},
		resetLeadsFilters: () => {
			return initialState;
		},
	},
});

export const {
	setCareCoordinator,
	unsetCareCoordinator,
	setPreDiagnosis,
	unsetPreDiagnosis,
	resetLeadsFilters,
} = leadsFiltersSlice.actions;

export default leadsFiltersSlice.reducer;
