import { Wrapper } from "@googlemaps/react-wrapper";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { ThemeProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/nextjs";
import { API, Amplify, Auth } from "aws-amplify";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/es";
import { NextComponentType, NextPageContext } from "next";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import CustomLoader from "../components/CustomLoader";
import "../helpers/i18n";

import {
	apiName,
	checkPermission,
	defaultRedirectingRouteBasedOnRole,
	getClinicCredentials,
	getClinicDomain,
} from "../helpers/utils";
import { useAuth } from "../hooks/auth";
import useIndexedDbSetter from "../hooks/useIndexedDbSetter";
import { setSnackBarOptions } from "../redux/snackbar";
import { AppDispatch, RootState, persistor, store } from "../redux/store";
import { setIsProfileImageUpdated } from "../redux/user";
import { setToken } from "../redux/zendeskAuth";
import "../styles/globals.css";
import { MuiTheme } from "../styles/theme";

import { clearAllReduxStates } from "../helpers";
import { connectToWebSocket } from "../redux/thunk/webSocket";

const ErrorBoundary = dynamic(() => import("../components/ErrorBoundary"), {
	ssr: false,
});

const SnackBar = dynamic(() => import("../components/SnackBar"), {
	ssr: false,
});

const InActivityWrapper = dynamic(
	() => import("../components/Wrappers/InActivityWrapper"),
);

function ComponentWrapper({
	Component,
	pageProps,
}: {
	Component: NextComponentType<NextPageContext, any, any>;
	pageProps: any;
}) {
	const { loading: indexedDBLoading } = useIndexedDbSetter();
	const router = useRouter();
	const dispatch = useDispatch<AppDispatch>();
	const { i18n } = useTranslation();
	const { loading: authLoading, isAuthenticated } = useAuth();
	const { languageLoader } = useSelector(
		(state: RootState) => state.language,
	);
	const { token } = useSelector((state: RootState) => state.zendeskAuth);
	const { id } = useSelector((state: RootState) => state.user);
	const [loading, setLoading] = useState(true);
	const [permissionsLoading, setPermissionsLoading] = useState(false);

	const { role, isProfileImageUpdated } = useSelector(
		(state: RootState) => state.user,
	);
	const { open, message, type } = useSelector(
		(state: RootState) => state.snackbar,
	);

	const onSnackBarClose = () => {
		dispatch(
			setSnackBarOptions({ open: !open, message: message, type: type }),
		);
	};

	// This useEffect runs when user changes their language and sets the dayjs locale to the selected language

	useEffect(() => {
		if (i18n.resolvedLanguage === "en") {
			dayjs.locale("en");
		} else if (i18n.resolvedLanguage === "fr") {
			dayjs.locale("fr");
		} else if (i18n.resolvedLanguage === "es") {
			dayjs.locale("es");
		}
	}, [i18n.resolvedLanguage]);

	const clearReduxStates = async () => {
		//If user is logged out but data is present in redux, we clear all the redux states
		const currentUser = await Auth.currentAuthenticatedUser().catch(
			() => {},
		);
		if (
			currentUser === undefined &&
			router.pathname.includes("/login") &&
			id
		) {
			clearAllReduxStates(dispatch);
		}
	};

	useEffect(() => {
		clearReduxStates();
	}, [Component]);

	const clinicId = getClinicDomain();
	const wsUrl = getClinicCredentials("WEBSOCKET_URL");
	useEffect(() => {
		// Websocket connection logic
		if (isAuthenticated) {
			dispatch(connectToWebSocket({ clinicId, wsUrl }));
		}
	}, [Component, isAuthenticated]);

	const updateUserProfilePicture = async () => {
		const currentUser = await Auth.currentAuthenticatedUser().catch(
			() => {},
		);
		if (currentUser !== undefined && isProfileImageUpdated === false) {
			await API.get(
				apiName,
				`api/user-management/get-profile-link`,
				{},
			).then((response) => {
				Auth.updateUserAttributes(currentUser, {
					picture: response.data,
				}).then(() => {
					dispatch(setIsProfileImageUpdated(true));
				});
			});
		}
	};

	const getZendeskToken = async () => {
		const currentUser = await Auth.currentAuthenticatedUser().catch(
			() => {},
		);
		if (currentUser !== undefined && token === "") {
			await API.post(apiName, `api/user-management/get-zendesk-token`, {
				body: {
					external_id: currentUser.attributes.sub,
					email: currentUser.attributes.email,
					email_verified: true,
					name:
						currentUser.attributes.given_name +
						" " +
						currentUser.attributes.family_name,
					scope: "user",
				},
			}).then((response) => {
				dispatch(setToken(response));
				// localStorage.setItem("zendeskToken", response);
				// zendeskApi("messenger", "loginUser", function (callback: any) {
				// 	console.log("user logged in");
				// 	callback(response);
				// });
			});
		}
	};

	useEffect(() => {
		updateUserProfilePicture();
	}, [Component]);

	useEffect(() => {
		// initial call to get zendesk token and save it in local storage if the user id exists
		if (id) {
			getZendeskToken();
		}
	}, [id]);

	useEffect(() => {
		if (role) {
			setPermissionsLoading(true);
			const allProtectedRoutes = [
				"dashboard",
				"referrals",
				"leads",
				"patients",
				"public-funded-waitlist",
				"referring-physician",
				"user-management",
				"configurations",
			];
			const currentRoute = router.pathname.split("/")[1] as
				| "dashboard"
				| "referrals"
				| "leads"
				| "patients"
				| "public-funded-waitlist"
				| "referring-physician"
				| "user-management"
				| "configurations";

			//Only checking permission on protectedRoutes
			if (allProtectedRoutes.includes(currentRoute)) {
				if (checkPermission(role, currentRoute, "view") === false) {
					router.replace("/404");
				} else {
					setPermissionsLoading(false);
				}
			} else {
				setPermissionsLoading(false);
			}
		}
	}, [Component]);

	useEffect(() => {
		setLoading(true);
		if (!authLoading) {
			if (isAuthenticated) {
				if (router.pathname === "/") {
					if (role) {
						router
							.replace(defaultRedirectingRouteBasedOnRole(role))
							.then(() => {
								setLoading(false);
							});
					}
				} else {
					setLoading(false);
				}
			} else {
				if (
					!router.pathname.includes("/user-invitation") &&
					!router.pathname.includes("/404") &&
					!router.pathname.includes("/forgot-password") &&
					!router.pathname.includes("/NewPassword") &&
					!router.pathname.includes("/PasswordExpired") &&
					!router.pathname.includes("/privacy-policy") &&
					!router.pathname.includes("/viewIcons")
				) {
					router.replace("/login").then(() => {
						setLoading(false);
					});
				} else {
					setLoading(false);
				}
			}
		}
	}, [authLoading, isAuthenticated, Component]);

	if (
		loading ||
		languageLoader ||
		authLoading ||
		permissionsLoading ||
		indexedDBLoading
	) {
		return <CustomLoader open={true} />;
	}

	return (
		<>
			<SnackBar
				open={open}
				onClose={onSnackBarClose}
				message={message}
				type={type}
			/>
			{/*  */}
			<InActivityWrapper />
			<Component {...pageProps} />
		</>
	);
}

const App = ({ Component, pageProps }: AppProps) => {
	useEffect(() => {
		//Configuring amplify
		Amplify.configure({
			Auth: {
				region: getClinicCredentials("AWS_REGION"),
				userPoolId: getClinicCredentials("AWS_USERPOOL_ID"),
				userPoolWebClientId: getClinicCredentials(
					"AWS_USERPOOL_APP_ID",
				),
				identityPoolId: getClinicCredentials("AWS_IDENTITYPOOL_ID"),
				authenticationFlowType:
					getClinicCredentials("AWS_AUTHFLOW_TYPE"),
				mandatorySignIn: true,
			},
			API: {
				endpoints: [
					{
						name: apiName,
						endpoint: getClinicCredentials("BACKEND_URL"),
						region: getClinicCredentials("AWS_REGION"),
						custom_header: async () => {
							return {
								idToken: `${(await Auth.currentSession())
									.getIdToken()
									.getJwtToken()}`,
							};
						},
					},
				],
			},
			Storage: {
				AWSS3: {
					bucket: getClinicCredentials("AWS_S3_BUCKET"),
					region: getClinicCredentials("AWS_REGION"),
				},
			},
		});
		// added tags for each clinic in sentry
		Sentry.setTag("clinic_name", getClinicDomain());
	}, []);

	const tagManagerArgs = {
		gtmId: getClinicCredentials("GOOGLE_TAG_MANAGER_ID") || "",
	};

	TagManager.initialize(tagManagerArgs);

	return (
		<>
			<Head>
				<meta
					name="viewport"
					content="width=1440, viewport-fit=cover"
				/>
				<meta name="description" content="Clinic Portal CNP" />
				<link
					rel="preconnect"
					href={`${getClinicCredentials("BACKEND_URL")}`}
				/>
				<link
					rel="icon"
					href={`${getClinicCredentials(
						"STATIC_CONTENT_BUCKET",
					)}/${getClinicDomain().toUpperCase()}/frontend_images/favicon.ico`}
				/>
			</Head>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<ThemeProvider theme={MuiTheme}>
						<StyledEngineProvider injectFirst>
							<ErrorBoundary>
								<Wrapper
									apiKey={getClinicCredentials(
										"GOOGLE_API_KEY",
									)}
									libraries={["places"]}
								>
									<ComponentWrapper
										Component={Component}
										pageProps={pageProps}
									/>
								</Wrapper>
							</ErrorBoundary>
						</StyledEngineProvider>
					</ThemeProvider>
				</PersistGate>
			</Provider>
		</>
	);
};

export default dynamic(() => Promise.resolve(App), {
	ssr: false,
});
