import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	permissions: [],
};

export const permissionsSlice = createSlice({
	name: "permissions",
	initialState,
	reducers: {
		setPermissions: (state, action) => {
			state.permissions = action.payload;
		},
		unsetPermissions: (state) => {
			state.permissions = [];
		},
		resetPermissions: () => {
			return initialState;
		},
	},
});

export const { setPermissions, unsetPermissions, resetPermissions } =
	permissionsSlice.actions;

export default permissionsSlice.reducer;
