import { createSlice } from "@reduxjs/toolkit";
import { SortingState } from "@tanstack/react-table";

interface FaxReferralsTableStateInterface {
	tab: "inbox" | "archived";
	search: string;
	inboxPageNumber: number;
	archivedPageNumber: number;
	inboxRowsPerPage: number;
	archivedRowsPerPage: number;
	inboxSorting: SortingState;
	archivedSorting: SortingState;
}
const initialState: FaxReferralsTableStateInterface = {
	tab: "inbox",
	search: "",
	inboxPageNumber: 1,
	archivedPageNumber: 1,
	inboxRowsPerPage: 20,
	archivedRowsPerPage: 20,
	inboxSorting: [],
	archivedSorting: [],
};

export const faxReferralsTableStateSlice = createSlice({
	name: "faxReferralsTableState",
	initialState,
	reducers: {
		setFaxReferralTab: (state, action) => {
			state.tab = action.payload;
		},
		setSearch: (state, action) => {
			state.search = action.payload;
		},
		setFaxReferralsInboxPageNumber: (state, action) => {
			state.inboxPageNumber = action.payload;
		},
		setFaxReferralsArchivedPageNumber: (state, action) => {
			state.archivedPageNumber = action.payload;
		},
		setInboxRowsPerPage: (state, action) => {
			state.inboxRowsPerPage = action.payload;
		},
		setArchivedRowsPerPage: (state, action) => {
			state.archivedRowsPerPage = action.payload;
		},
		setInboxSorting: (state, action) => {
			state.inboxSorting = action.payload;
		},
		setArchivedSorting: (state, action) => {
			state.archivedSorting = action.payload;
		},
		resetTableState: () => {
			return initialState;
		},
	},
});

export const {
	setFaxReferralTab,
	setSearch,
	setFaxReferralsInboxPageNumber,
	setFaxReferralsArchivedPageNumber,
	setInboxRowsPerPage,
	setArchivedRowsPerPage,
	setInboxSorting,
	setArchivedSorting,
	resetTableState,
} = faxReferralsTableStateSlice.actions;

export default faxReferralsTableStateSlice.reducer;
