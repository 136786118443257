import { createSlice } from "@reduxjs/toolkit";
import { SortingState } from "@tanstack/react-table";

interface PatientsTableStateInterface {
	tab: "active" | "archived";
	search: string;
	activePageNumber: number;
	archivedPageNumber: number;
	activeRowsPerPage: number;
	archivedRowsPerPage: number;
	patientActiveSorting: SortingState;
	patientArchivedSorting: SortingState;
}
const initialState: PatientsTableStateInterface = {
	tab: "active",
	search: "",
	activePageNumber: 1,
	archivedPageNumber: 1,
	activeRowsPerPage: 20,
	archivedRowsPerPage: 20,
	patientActiveSorting: [],
	patientArchivedSorting: [],
};

export const patientsTableStateSlice = createSlice({
	name: "patientsTableState",
	initialState,
	reducers: {
		setPatientsTab: (state, action) => {
			state.tab = action.payload;
		},
		setPatientsSearch: (state, action) => {
			state.search = action.payload;
		},
		setPatientsActivePageNumber: (state, action) => {
			state.activePageNumber = action.payload;
		},
		setPatientsArchivedPageNumber: (state, action) => {
			state.archivedPageNumber = action.payload;
		},
		setPatientsActiveRowsPerPage: (state, action) => {
			state.activeRowsPerPage = action.payload;
		},
		setPatientsArchivedRowsPerPage: (state, action) => {
			state.archivedRowsPerPage = action.payload;
		},
		setPatientsActiveSorting: (state, action) => {
			state.patientActiveSorting = action.payload;
		},
		setPatientsArchivedSorting: (state, action) => {
			state.patientArchivedSorting = action.payload;
		},
		resetPatientsTableState: () => {
			return initialState;
		},
	},
});

export const {
	setPatientsTab,
	setPatientsSearch,
	setPatientsActivePageNumber,
	setPatientsArchivedPageNumber,
	setPatientsActiveRowsPerPage,
	setPatientsArchivedRowsPerPage,
	setPatientsActiveSorting,
	setPatientsArchivedSorting,
	resetPatientsTableState,
} = patientsTableStateSlice.actions;

export default patientsTableStateSlice.reducer;
