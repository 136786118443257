import { API, Auth, Hub } from "aws-amplify";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { apiName, defaultRedirectingRouteBasedOnRole } from "../helpers/utils";
import { id, role } from "../redux/user";
import { clearAllReduxStates } from "../helpers";

export const useAuth = () => {
	const dispatch = useDispatch();
	const { i18n } = useTranslation();
	const currentLanguage = i18n.resolvedLanguage;
	const [loading, setLoading] = useState(true);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [user, setUser] = useState(null);
	const router = useRouter();
	// const allowedPaths = [
	// 	"/create-password",
	// 	"/reset-password",
	// 	"/change-password",
	// 	"/user-invitation",
	// 	"/forgot-password",
	// 	"/NewPassword",
	// 	"/PasswordExpired",
	// ];

	useEffect(() => {
		setLoading(true);
		// Check if the user is already authenticated on initial load
		Auth.currentAuthenticatedUser()
			.then((currentUser) => {
				setIsAuthenticated(true);
				setUser(currentUser);
				dispatch(id(currentUser.attributes.sub));
				dispatch(
					role(
						currentUser.signInUserSession.accessToken.payload[
							"cognito:groups"
						].filter(
							(roles: string) => roles.split("_")[0] === "CNP",
						)[0],
					),
				);
			})
			.catch(() => {
				setIsAuthenticated(false);
				setUser(null);
				// if (
				// 	!allowedPaths.some((path) => router.pathname.includes(path))
				// ) {
				// 	router.push("/login");
				// }
			})
			.finally(() => {
				setLoading(false);
			});

		// Subscribe to authentication events
		const authListener = Hub.listen(
			"auth",
			async ({ payload: { event, data } }: any) => {
				switch (event) {
					case "signIn":
						//case "cognitoHostedUI":
						setIsAuthenticated(true);
						setUser(data);
						if (data.attributes.locale) {
							// i18n.changeLanguage(data.attributes.locale); // This changes users locale to the one saved in cognito
							await Auth.updateUserAttributes(data, {
								locale: currentLanguage,
							});
						}
						// const userRole =
						// 	data.signInUserSession.accessToken.payload[
						// 		"cognito:groups"
						// 	][0];
						let userRole;
						const roles =
							data.signInUserSession.accessToken.payload[
								"cognito:groups"
							];
						for (const role of roles) {
							if (role.startsWith("CNP")) {
								userRole = role;
							}
						}
						// console.log(
						//   "role is",
						//   data.signInUserSession.accessToken.payload["cognito:groups"]
						// );
						if (userRole) {
							router.push(
								defaultRedirectingRouteBasedOnRole(userRole),
							);
						}
						break;
					case "signOut":
						setIsAuthenticated(false);
						setUser(null);
						router.push("/login");
						router.reload(); // refresh the layout
						break;
					default:
						break;
				}
			},
		);

		// Clean up the listener when the component unmounts
		return () => {
			authListener();
		};
	}, []);

	const logout = async () => {
		await Auth.currentAuthenticatedUser()
			.then(async (currentUser) => {
				await API.post(apiName, `/api/websocket-logout`, {
					body: { user_id: currentUser.attributes.sub },
				}).then(async (data) => {
					await Auth.signOut().then(() => {
						// Clearing all the redux states
						clearAllReduxStates(dispatch);
					});
				});
			})
			.catch((error) => {
				console.log("Sign Out API error:", error);
			});
	};

	return { loading, isAuthenticated, user, logout };
};
