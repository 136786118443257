import { createSlice } from "@reduxjs/toolkit";

interface AddPartnerPatientsData {
	patientsPrimaryPatientId: number;
	patientsPrimaryPatientName: string;
	patientsPrimaryPatientEmail: string;
	patientsAddress_1: string;
	patientsAddress_2: string;
	patientsAddress_city_id: number;
	patientsCity: {
		id: number;
		name: string;
		status: number;
	} | null;
	patientsAddress_latitude: number;
	patientsAddress_longitude: number;
	patientsAddress_postal_code: string;
	patientsPlace_id: string;
	patientsProvince_id: number;
	patientsCountry_id: number;
	patientsSameAsPrimaryAddress: boolean;
	patientPharmacy: {
		pharmacy_name: string;
		pharmacy_phone: string;
		pharmacy_fax: string;
		pharmacy_address: string;
		pharmacy_country_id: number;
		pharmacy_province_id: number;
		pharmacy_city_id: number;
		pharmacy_place_id: string;
		pharmacy_address_latitude: number;
		pharmacy_address_longitude: number;
	};
}
const initialState: AddPartnerPatientsData = {
	patientsPrimaryPatientId: 0,
	patientsPrimaryPatientName: "",
	patientsPrimaryPatientEmail: "",
	patientsAddress_1: "",
	patientsAddress_2: "",
	patientsAddress_city_id: 0,
	patientsCity: null,
	patientsAddress_latitude: 0,
	patientsAddress_longitude: 0,
	patientsAddress_postal_code: "",
	patientsPlace_id: "",
	patientsProvince_id: 0,
	patientsCountry_id: 0,
	patientsSameAsPrimaryAddress: false,
	patientPharmacy: {
		pharmacy_name: "",
		pharmacy_phone: "",
		pharmacy_fax: "",
		pharmacy_address: "",
		pharmacy_country_id: 0,
		pharmacy_province_id: 0,
		pharmacy_city_id: 0,
		pharmacy_place_id: "",
		pharmacy_address_latitude: 0,
		pharmacy_address_longitude: 0,
	},
};

export const addPartnerPatientsDataSlice = createSlice({
	name: "addPartnerPatientsData",
	initialState,
	reducers: {
		patientsSetPrimaryPatientData: (state, action) => {
			state.patientsPrimaryPatientId = action.payload.id;
			state.patientsPrimaryPatientName = action.payload.name;
			state.patientsPrimaryPatientEmail = action.payload.email;
			state.patientsAddress_1 = action.payload.patientsAddress_1;
			state.patientsAddress_2 = action.payload.patientsAddress_2;
			state.patientsAddress_city_id =
				action.payload.patientsAddress_city_id;
			state.patientsCity = action.payload.patientsCity;
			state.patientsAddress_latitude =
				action.payload.patientsAddress_latitude;
			state.patientsAddress_longitude =
				action.payload.patientsAddress_longitude;
			state.patientsAddress_postal_code =
				action.payload.patientsAddress_postal_code;
			state.patientsPlace_id = action.payload.patientsPlace_id;
			state.patientsProvince_id = action.payload.patientsProvince_id;
			state.patientsCountry_id = action.payload.patientsCountry_id;
			state.patientsSameAsPrimaryAddress =
				action.payload.patientsSameAsPrimaryAddress;
			state.patientPharmacy = action.payload.patientPharmacy;
		},
		patientsUnSetPrimaryPatientData: () => {
			return initialState;
		},
	},
});

export const {
	patientsSetPrimaryPatientData,
	patientsUnSetPrimaryPatientData,
} = addPartnerPatientsDataSlice.actions;

export default addPartnerPatientsDataSlice.reducer;
