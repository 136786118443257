import { createSlice } from "@reduxjs/toolkit";

const initialState: {
	careCoordinator: string;
	preDiagnosis: number;
} = {
	careCoordinator: "-1",
	preDiagnosis: -1,
};

export const patientsFiltersSlice = createSlice({
	name: "leadsFilters",
	initialState,
	reducers: {
		setPatientCareCoordinator: (state, action) => {
			state.careCoordinator = action.payload;
		},
		unsetPatientCareCoordinator: (state) => {
			state.careCoordinator = "-1";
		},
		setPatientPreDiagnosis: (state, action) => {
			state.preDiagnosis = action.payload;
		},
		unsetPatientPreDiagnosis: (state) => {
			state.preDiagnosis = -1;
		},
		resetPatientsFilters: () => {
			return initialState;
		},
	},
});

export const {
	setPatientCareCoordinator,
	unsetPatientCareCoordinator,
	setPatientPreDiagnosis,
	unsetPatientPreDiagnosis,
	resetPatientsFilters,
} = patientsFiltersSlice.actions;

export default patientsFiltersSlice.reducer;
