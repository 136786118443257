import { createSlice } from "@reduxjs/toolkit";
import { SortingState } from "@tanstack/react-table";

interface ReferralsTableStateInterface {
	tab: "inbox" | "archived";
	search: string;
	inboxPageNumber: number;
	archivedPageNumber: number;
	inboxRowsPerPage: number;
	archivedRowsPerPage: number;
	referralInboxSorting: SortingState;
	referralArchivedSorting: SortingState;
}
const initialState: ReferralsTableStateInterface = {
	tab: "inbox",
	search: "",
	inboxPageNumber: 1,
	archivedPageNumber: 1,
	inboxRowsPerPage: 20,
	archivedRowsPerPage: 20,
	referralInboxSorting: [],
	referralArchivedSorting: [],
};

export const referralsTableStateSlice = createSlice({
	name: "referralsTableState",
	initialState,
	reducers: {
		setReferralsTab: (state, action) => {
			state.tab = action.payload;
		},
		setReferralsSearch: (state, action) => {
			state.search = action.payload;
		},
		setReferralsInboxPageNumber: (state, action) => {
			state.inboxPageNumber = action.payload;
		},
		setReferralsArchivedPageNumber: (state, action) => {
			state.archivedPageNumber = action.payload;
		},
		setReferralsInboxRowsPerPage: (state, action) => {
			state.inboxRowsPerPage = action.payload;
		},
		setReferralsArchivedRowsPerPage: (state, action) => {
			state.archivedRowsPerPage = action.payload;
		},
		setReferralInboxSorting: (state, action) => {
			state.referralInboxSorting = action.payload;
		},
		setReferralArchivedSorting: (state, action) => {
			state.referralArchivedSorting = action.payload;
		},
		resetReferralsTableState: () => {
			return initialState;
		},
	},
});

export const {
	setReferralsTab,
	setReferralsSearch,
	setReferralsInboxPageNumber,
	setReferralsArchivedPageNumber,
	setReferralsInboxRowsPerPage,
	setReferralsArchivedRowsPerPage,
	setReferralInboxSorting,
	setReferralArchivedSorting,
	resetReferralsTableState,
} = referralsTableStateSlice.actions;

export default referralsTableStateSlice.reducer;
