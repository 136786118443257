import { createSlice } from "@reduxjs/toolkit";

export const languageSlice = createSlice({
	name: "language",
	initialState: {
		languageLoader: false,
	},
	reducers: {
		setLanguageLoader: (state, action) => {
			state.languageLoader = action.payload;
		},
		resetLanguageLoader: (state) => {
			state.languageLoader = false;
		},
	},
});

export const { setLanguageLoader, resetLanguageLoader } = languageSlice.actions;

export default languageSlice.reducer;
