import { createSlice } from "@reduxjs/toolkit";
import { connectToWebSocket } from "./thunk/webSocket";

const initialState: IWebSocketState = {
	refreshReferralInbox: false,
	refreshLeadInbox: false,
	reloadConfig: false,
	isConnected: false,
	eventName: "",
	eventData: null,
	isConnecting: false,
	error: undefined,
};

const webSocketSlice = createSlice({
	name: "webSocket",
	initialState,
	reducers: {
		setRefreshReferralInbox: (state, action) => {
			state.refreshReferralInbox = action.payload;
		},
		setRefreshLeadInbox: (state, action) => {
			state.refreshLeadInbox = action.payload;
		},
		setReloadConfig: (state, action) => {
			state.reloadConfig = action.payload;
		},
		setConnectionStatus: (state, action) => {
			state.isConnected = action.payload;
		},
		setEventName: (state, action) => {
			state.eventName = action.payload;
		},
		setEventData: (state, action) => {
			state.eventData = action.payload;
		},
		resetWebSocket: () => {
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(connectToWebSocket.pending, (state) => {
			state.isConnecting = true;
			state.error = undefined;
		});

		builder.addCase(connectToWebSocket.fulfilled, (state) => {
			state.isConnected = true;
			state.isConnecting = false;
		});

		builder.addCase(connectToWebSocket.rejected, (state, action) => {
			state.isConnecting = false;
			state.isConnected = false;
			state.error = action.error.message;
		});
	},
});

export const {
	setRefreshReferralInbox,
	setRefreshLeadInbox,
	setReloadConfig,
	resetWebSocket,
	setConnectionStatus,
	setEventName,
	setEventData,
} = webSocketSlice.actions;
export default webSocketSlice.reducer;
