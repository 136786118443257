import { createSlice } from "@reduxjs/toolkit";

interface AddPartnerData {
	primaryPatientId: number;
	primaryPatientName: string;
	primaryPatientEmail: string;
	address_1: string;
	address_2: string;
	address_city_id: number;
	city: {
		id: number;
		name: string;
		status: number;
	} | null;
	address_latitude: number;
	address_longitude: number;
	address_postal_code: string;
	place_id: string;
	province_id: number;
	country_id: number;
	sameAsPrimaryAddress: boolean;
	pharmacy: {
		pharmacy_name: string;
		pharmacy_phone: string;
		pharmacy_fax: string;
		pharmacy_address: string;
		pharmacy_country_id: number;
		pharmacy_province_id: number;
		pharmacy_city_id: number;
		pharmacy_place_id: string;
		pharmacy_address_latitude: number;
		pharmacy_address_longitude: number;
	};
}
const initialState: AddPartnerData = {
	primaryPatientId: 0,
	primaryPatientName: "",
	primaryPatientEmail: "",
	address_1: "",
	address_2: "",
	address_city_id: 0,
	city: null,
	address_latitude: 0,
	address_longitude: 0,
	address_postal_code: "",
	place_id: "",
	province_id: 0,
	country_id: 0,
	sameAsPrimaryAddress: false,
	pharmacy: {
		pharmacy_name: "",
		pharmacy_phone: "",
		pharmacy_fax: "",
		pharmacy_address: "",
		pharmacy_country_id: 0,
		pharmacy_province_id: 0,
		pharmacy_city_id: 0,
		pharmacy_place_id: "",
		pharmacy_address_latitude: 0,
		pharmacy_address_longitude: 0,
	},
};

export const addPartnerDataSlice = createSlice({
	name: "addPartnerData",
	initialState,
	reducers: {
		setPrimaryPatientData: (state, action) => {
			state.primaryPatientId = action.payload.id;
			state.primaryPatientName = action.payload.name;
			state.primaryPatientEmail = action.payload.email;
			state.address_1 = action.payload.address_1;
			state.address_2 = action.payload.address_2;
			state.address_city_id = action.payload.address_city_id;
			state.city = action.payload.city;
			state.address_latitude = action.payload.address_latitude;
			state.address_longitude = action.payload.address_longitude;
			state.address_postal_code = action.payload.address_postal_code;
			state.place_id = action.payload.place_id;
			state.province_id = action.payload.province_id;
			state.country_id = action.payload.country_id;
			state.sameAsPrimaryAddress = action.payload.sameAsPrimaryAddress;
			state.pharmacy = action.payload.pharmacy;
		},
		unSetPrimaryPatientData: () => {
			return initialState;
		},
	},
});

export const { setPrimaryPatientData, unSetPrimaryPatientData } =
	addPartnerDataSlice.actions;

export default addPartnerDataSlice.reducer;
