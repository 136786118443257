import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	token: "",
	locale: "en",
};

export const zendeskAuthSlice = createSlice({
	name: "zendeskAuth",
	initialState,
	reducers: {
		setToken: (state, action) => {
			state.token = action.payload;
		},
		setLocale: (state, action) => {
			state.locale = action.payload;
		},
		resetAuth: () => {
			return initialState;
		},
	},
});

export const { setToken, setLocale, resetAuth } = zendeskAuthSlice.actions;
export default zendeskAuthSlice.reducer;
