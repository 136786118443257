import { createSlice } from "@reduxjs/toolkit";
import { SortingState } from "@tanstack/react-table";

interface UserManagementTableStateInterface {
	tab: "teams";
	search: string;
	teamsPageNumber: number;
	teamsRowsPerPage: number;
	teamsSorting: SortingState;
}
const initialState: UserManagementTableStateInterface = {
	tab: "teams",
	search: "",
	teamsPageNumber: 1,
	teamsRowsPerPage: 20,
	teamsSorting: [],
};

export const userManagementTableStateSlice = createSlice({
	name: "userManagementTableState",
	initialState,
	reducers: {
		setUserManagementTab: (state, action) => {
			state.tab = action.payload;
		},
		setUserManagementSearch: (state, action) => {
			state.search = action.payload;
		},
		setUserManagementTeamsPageNumber: (state, action) => {
			state.teamsPageNumber = action.payload;
		},
		setUserManagementTeamsRowsPerPage: (state, action) => {
			state.teamsRowsPerPage = action.payload;
		},
		setTeamsSorting: (state, action) => {
			state.teamsSorting = action.payload;
		},
		resetUserManagementTableState: () => {
			return initialState;
		},
	},
});

export const {
	setUserManagementTab,
	setUserManagementSearch,
	setUserManagementTeamsPageNumber,
	setUserManagementTeamsRowsPerPage,
	resetUserManagementTableState,
	setTeamsSorting,
} = userManagementTableStateSlice.actions;

export default userManagementTableStateSlice.reducer;
