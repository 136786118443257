import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./enTranslations";
import fr from "./frTranslations";

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: "en",
		supportedLngs: ["en", "fr"],
		nonExplicitSupportedLngs: true,
		interpolation: {
			escapeValue: false,
		},
		resources: {
			en: {
				translation: en,
			},
			fr: {
				translation: fr,
			},
		},
	});

export default i18n;
