import { createSlice } from "@reduxjs/toolkit";
import { SortingState } from "@tanstack/react-table";

interface ReferringPhysiciansTableStateInterface {
	tab: "physician" | "campaign";
	search: string;
	physicianPageNumber: number;
	campaignPageNumber: number;
	physicianRowsPerPage: number;
	campaignRowsPerPage: number;
	physicianSorting: SortingState;
	campaignSorting: SortingState;
}
const initialState: ReferringPhysiciansTableStateInterface = {
	tab: "physician",
	search: "",
	physicianPageNumber: 1,
	campaignPageNumber: 1,
	physicianRowsPerPage: 20,
	campaignRowsPerPage: 20,
	physicianSorting: [],
	campaignSorting: [],
};

export const referringPhysiciansTableStateSlice = createSlice({
	name: "referringPhysiciansTableState",
	initialState,
	reducers: {
		setReferringPhysiciansTab: (state, action) => {
			state.tab = action.payload;
		},
		setReferringPhysiciansSearch: (state, action) => {
			state.search = action.payload;
		},
		setReferringPhysiciansPhysicianPageNumber: (state, action) => {
			state.physicianPageNumber = action.payload;
		},
		setReferringPhysiciansCampaignPageNumber: (state, action) => {
			state.campaignPageNumber = action.payload;
		},
		setReferringPhysiciansPhysicianRowsPerPage: (state, action) => {
			state.physicianRowsPerPage = action.payload;
		},
		setReferringPhysiciansCampaignRowsPerPage: (state, action) => {
			state.campaignRowsPerPage = action.payload;
		},
		setPhysicianSorting: (state, action) => {
			state.physicianSorting = action.payload;
		},
		setCampaignSorting: (state, action) => {
			state.campaignSorting = action.payload;
		},
		resetReferringPhysiciansTableState: () => {
			return initialState;
		},
	},
});

export const {
	setReferringPhysiciansTab,
	setReferringPhysiciansSearch,
	setReferringPhysiciansPhysicianPageNumber,
	setReferringPhysiciansCampaignPageNumber,
	setReferringPhysiciansPhysicianRowsPerPage,
	setReferringPhysiciansCampaignRowsPerPage,
	resetReferringPhysiciansTableState,
	setPhysicianSorting,
	setCampaignSorting,
} = referringPhysiciansTableStateSlice.actions;

export default referringPhysiciansTableStateSlice.reducer;
