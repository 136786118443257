import { createSlice } from "@reduxjs/toolkit";

interface LeadsTableStateInterface {
	menus: Array<{
		id: number;
		parent_id: number;
		name: string;
		sort: number;
		status: number;
		created_at: string | null;
		updated_at: string | null;
		pivot: {
			role_id: number;
			menu_id: number;
		};
	}>;
}
const initialState: LeadsTableStateInterface = {
	menus: [],
};

export const menuesSlice = createSlice({
	name: "menus",
	initialState,
	reducers: {
		setMenues: (state, action) => {
			state.menus = action.payload;
		},
		unsetMenues: (state) => {
			state.menus = [];
		},
		resetMenus: () => {
			return initialState;
		},
	},
});

export const { setMenues, unsetMenues, resetMenus } = menuesSlice.actions;

export default menuesSlice.reducer;
