import React from "react";
import IcomoonReact from "icomoon-react";
import iconSet from "./selection.json";
import { useTheme } from "@mui/material";

interface IconProps {
	id?: string;
	className?: string;
	color?: string;
	icon: string;
	size?: string | number;
}

const Icon = ({
	id = "",
	className = "",
	color = "",
	icon,
	size = 24,
}: IconProps) => {
	// Theme selector
	const theme = useTheme();

	// If color is not provided, use the primary color from the theme
	const iconColor = color || theme.palette.primary.main;

	return (
		<IcomoonReact
			id={icon}
			className={className}
			iconSet={iconSet}
			color={iconColor}
			size={size}
			icon={icon}
		/>
	);
};

export default Icon;
